<template>
  <div>
    <div v-if="!loaded" class="d-flex justify-content-center mb-3">
      <ct-centered-spinner />
    </div>
    <div v-else>
      <b-card>
        <b-card-text v-if="bundleIsNotCompatible">
          <h3>
            Problem detected
          </h3>
          <p>
            It looks like this bundle is not compatible with the entity type of the company you have selected.
          </p>
        </b-card-text>
        <b-card-text v-else-if="availableFilingProducts.length > 0">
          <h3>
            Filing Options for this Bundle
          </h3>
          <div v-for="(product, index) in availableFilingProducts" :key="index">
            <filing-options
              v-if="index === currentTab"
              :is-displaying-bundle="true"
              :bundle-base-price="bundle.price"
              :submission-label="index === availableFilingProducts.length-1 ? 'Add to Cart' : 'Next'"
              :product="product"
              :company="company"
              :saved-items="selectedFilings"
              :allow-multi-jurisdiction="false"
              @remove-by-id="removeBundleFromCart"
              @submit="addSelectionToSaved"
            />
          </div>

          <br>
          <p>
            Included in Bundle
          </p>
          <ul>
            <li v-for="(item, i) in bundle.products" :key="i">
              {{ item.name }}
            </li>
            <li v-if="raIncluded">
              Registered Agent Service
            </li>
          </ul>
        </b-card-text>
        <template v-else>
          <b-card-text>
            <h3>
              {{ bundle.name }} - {{ bundle.price | dollars }}
            </h3>

            <br>
            <p>
              Included in Bundle
            </p>
            <ul>
              <li v-for="(item, i) in bundle.products" :key="i">
                {{ item.name }}
                <span v-if="item.is_free_trial">
                  - <i>Free Trial</i>
                </span>
              </li>
              <li v-if="bundle.ra_product_count > 0">
                Registered Agent Service
              </li>
            </ul>
          </b-card-text>
          <b-button
            variant="primary"
            class="float-right mt-1"
            aria-label="add to cart button"
            :disabled="bundleInCart"
            @click="submitBundle()"
          >
            Add to Cart
          </b-button>
        </template>
      </b-card>
    </div>
  </div>
</template>

<script>
  import { dollars } from '@/filters'
  import { mapActions, mapGetters } from 'vuex'
  import {
    addBoiWithFilingProduct,
    addFilingProductToBundleItems,
    addRenewalWithFilingProduct,
    raAddedToBundleItems,
  } from '@/components/HireUs/helper'

  export default {
    name: 'BundleCheckout',
    filters: {
      dollars,
    },

    components: {
      FilingOptions:     () => import('@/components/HireUs/FilingOptions'),
      CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner'),
    },
    props: {
      bundle: Object,
      company: Object,
    },
    data() {
      return {
        loaded: false,
        currentTab: 0,
        selectedRegion: '',
      }
    },
    computed: {
      ...mapGetters('checkout', [
        'registeredAgentProductByJurisdiction',
        'findProduct',
        'cartItems',
        'productBundleTotal',
        'currentBundleItems',
      ]),
      ...mapGetters('companies', [
        'activeRAServices',
        'hasExistingRAServiceInJurisdiction',
      ]),
      filingProducts() {
        return this.bundle.products
          .filter(product => product.kind === 'filing_product')
          .filter(product => !product.name.includes('Tax ID'))
      },
      // Filing methods can be returned with Bundles, so only look them up in list of products
      //  that was already downloaded by the checkout module if they are not present.
      availableFilingProducts() {
        return this.filingProducts.map(product => {
          return product?.filing_methods.length > 0 ?
            product :
            this.findProduct(product.id)
        }).flat()
      },
      // The user may have selected a bundle and company that are not compatible.
      // For example, a company that is a Sole Proprietorship might have zero "Form a
      // Company" filing methods, in which case a "Form a Company" bundle is not possible.
      bundleIsNotCompatible() {
        return !this.bundle.products.length ||
          !this.availableFilingProducts ||
          this.availableFilingProducts.length < this.filingProducts.length
      },
      bundleInCart() {
        return !!this.cartItems.filter((x) => x.product_id === this.bundle.id).length
      },
      bundleFilings() {
        return Object.values(this.currentBundleItems)
          .filter(record => record.kind === 'filing_product')
      },
      selectedFilings() {
        return this.bundleInCart ? this.bundleFilings : []
      },
      raIncluded() {
        return this.bundle.ra_product_count > 0 &&
          !this.hasExistingRAServiceInJurisdiction(this.selectedRegion)
      },
    },
    watch: {
      // Reset if an item has been removed from the cart.
      cartItems(currentCartItems, previousItemsInCart) {
        if(currentCartItems.length < previousItemsInCart.length) {
          this.checkForBundleFreeTrialsAndCalculateTotal({ productBundle: this.bundle })
        }
      },
    },
    async mounted() {
      await this.loadActiveRAServices({ id: this.company.id })
      const bundleCartItem = this.cartItems.filter((x) => x.product_id === this.bundle.id)[0]

      if (bundleCartItem) {
        this.bundle.products.forEach(bundleProduct => {
          if (bundleProduct && bundleCartItem?.data[bundleProduct.id]) {
            bundleProduct.filing_method = bundleCartItem.data[bundleProduct.id].filing_method
          }
        })
      }

      await this.checkForBundleFreeTrialsAndCalculateTotal({ productBundle: this.bundle })
      this.loaded = true
    },
    methods: {
      ...mapActions('checkout', [
        'addBundleToCart',
        'removeFromCart',
        'setBundleProduct',
        'checkForBundleFreeTrialsAndCalculateTotal',
      ]),
      ...mapActions('companies', ['loadActiveRAServices']),
      ...mapActions('freeTrials', ['determineTrialEligibilityBatch']),
      async addSelectionToSaved(filingInformation) {
        const product = filingInformation.product
        const filingMethod = filingInformation.filingMethod

        // The chosen jurisdiction will be used as a default for RA services and compliance products.
        this.selectedRegion = filingInformation.jurisdiction
        product.filing_method = filingMethod

        await addFilingProductToBundleItems(product)

        await addRenewalWithFilingProduct({
          filingProduct: product,
          company: this.company,
          jurisdiction: this.selectedRegion,
          productCartItemId: null,
        })

        await addBoiWithFilingProduct(product)

        await this.next()
      },
      async next() {
        if (this.currentTab === this.availableFilingProducts.length-1) {
          this.autoSelectSimpleServices()
          await raAddedToBundleItems(this.bundle, this.selectedRegion)
          await this.submitBundle()
        } else {
          this.currentTab++
        }
      },
      autoSelectSimpleServices() {
        if (this.bundleFilings.length > 0) {
          Object.keys(this.currentBundleItems).forEach(key => {
            const product = this.currentBundleItems[key]

            // Choose state for compliance based on previous user selection.
            if (product.kind === 'simple_product' && product.name.includes('Compliance')) {
              product.data = { state: this.selectedRegion }
            }

            // Choose first available filing method for tax products.
            if (product.kind === 'filing_product' && product.name.includes('Tax ID')) {
              const originalProduct = this.bundle.products.find(item => item.id === product.product_id)
              // eslint-disable-next-line no-undef
              product.product_option_id = _.get(originalProduct, "['filing_methods'][0]['id']", null)
            }
          })
        }
      },
      async submitBundle() {
        this.$emit('product-added', this.bundle)
        await this.addBundleToCart({
          bundle: {
            ...this.bundle,
            data: this.currentBundleItems,
          },
        })
      },
      async removeBundleFromCart() {
        const item = this.cartItems.find(i => i.product_id === this.bundle.id)

        if (item) {
          await this.removeFromCart(item)
          this.bundle = await this.checkForBundleFreeTrialsAndCalculateTotal({ productBundle: this.bundle })
          this.currentTab = 0
        }
      },
    },
  }
</script>
